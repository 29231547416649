import "./Messenger.scss";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState
} from "react";

import {
  ACTION_ADD_CANNED_MESSAGE,
  ACTION_DRAFT_LOADED,
  ACTION_RESET,
  ACTION_SET_VALUE,
  createInitialState,
  reducer
} from "./reducer";
import { useLocalStorageDraft } from "./useLocalStorageDraft";
import { useLocalStorageSuggestedResponse } from "./useLocalStorageSuggestedResponse";
import { useLocalStorageSuggestedResponseTracker } from "./useLocalStorageSuggestedResponseTracker";
import { possibleEmptyTemplates } from "./utils";

/**
 * HOC – all the shared logic for both AirbnbMessenger and BookingSyncMessenger.
 */
const withMessenger = WrappedComponent => {
  const WithMessenger = props => {
    const { booking, guestName, property, thread } = props;
    const { getSuggestedResponse } = useLocalStorageSuggestedResponse(
      thread,
      booking,
      property
    );

    const {
      getIsSuggestedResponseUsed,
      setIsSuggestedResponseUsed
    } = useLocalStorageSuggestedResponseTracker();

    const [reducerState, dispatch] = useReducer(
      reducer,
      { property, guestName },
      createInitialState
    );

    const [isModified, setIsModified] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [cannedMessageValue, setCannedMessageValue] = useState("");
    const setValue = useCallback(
      value => dispatch({ type: ACTION_SET_VALUE, value }),
      []
    );
    const value = useMemo(() => reducerState.value, [reducerState.value]);
    const setSuggestedValue = useCallback(
      value => {
        dispatch({ type: ACTION_SET_VALUE, value });
        setIsSuggestedResponseUsed(true);
      },
      [setIsSuggestedResponseUsed]
    );

    useLocalStorageDraft({
      state: {
        draftLoaded: reducerState.draftLoaded,
        cannedMessages: reducerState.cannedMessages,
        value
      },
      onDraftLoad: () => dispatch({ type: ACTION_DRAFT_LOADED }),
      onDraftRestore: setValue,
      isModified
    });

    const handleCannedMessageSelection = useCallback(
      ({ value }) => {
        setCannedMessageValue(value);
        dispatch({ type: ACTION_ADD_CANNED_MESSAGE, value });
      },
      [setCannedMessageValue]
    );

    const handleTextareaFocus = useCallback(() => {
      setIsExpanded(true);
    }, [setIsExpanded]);

    const handleReset = useCallback(() => {
      dispatch({ type: ACTION_RESET });
      setCannedMessageValue("");
    }, []);

    // Updates the isModified state on value changes
    // TODO: would be best to have this in the reducer
    useEffect(() => {
      const valueIsNotOneOfEmptyTemplates = !possibleEmptyTemplates(
        property,
        guestName
      ).includes(value);

      setIsModified(valueIsNotOneOfEmptyTemplates);
    }, [guestName, handleReset, property, reducerState.cannedMessages, value]);

    const getAiGeneratedMessage = () => {
      const usedAiResponse = getIsSuggestedResponseUsed();
      const aiResponse = getSuggestedResponse();

      return usedAiResponse && aiResponse
        ? {
            ai_generated_message: aiResponse
          }
        : {};
    };

    const getDevConfirmation = () => {
      if (process.env.NODE_ENV === "development") {
        return window.confirm(
          "Dear dev, are you really sure you want to do this?"
        );
      }

      return true;
    };

    const messengerUIProps = useMemo(
      () => ({
        booking,
        cannedMessageValue,
        dispatch,
        handleCannedMessageSelection,
        handleReset,
        handleTextareaFocus,
        isExpanded,
        isModified,
        property,
        setIsExpanded,
        setSuggestedValue,
        setValue,
        thread,
        value
      }),
      [
        booking,
        cannedMessageValue,
        dispatch,
        handleCannedMessageSelection,
        handleReset,
        handleTextareaFocus,
        isExpanded,
        isModified,
        property,
        setIsExpanded,
        setSuggestedValue,
        setValue,
        thread,
        value
      ]
    );

    return (
      <WrappedComponent
        getDevConfirmation={getDevConfirmation}
        getAiGeneratedMessage={getAiGeneratedMessage}
        guestName={guestName}
        handleReset={handleReset}
        messengerUIProps={messengerUIProps}
        message={reducerState.value}
        {...props}
      />
    );
  };

  WithMessenger.displayName = `withMessenger(${WrappedComponent.displayName ||
    WrappedComponent.name})`;

  return WithMessenger;
};

export default withMessenger;
