export const AIRBASE_URL = "https://airbase.houst.com";

export const AIRBASE_PROPERTY_URL = `${AIRBASE_URL}/properties`;

export const AIRBASE_BOOKINGS_URL = `${AIRBASE_URL}/bookings`;

export const AIRBOX_STAGING_URL = "airbox.staging.houst.com";

export const FRONT_LIB_URL = "https://dl.frontapp.com/libs/frontjs.min.js";

export const AIRBNB_USER_URL = "https://www.airbnb.com/users/show";
export const AIRBNB_LOGIN_URL = "https://www.airbnb.com/login";
export const AIRBNB_LISTING_URL = "https://www.airbnb.com/rooms";
export const AIRBNB_SUPPORT_THREAD_TYPE = "support_messaging_thread";

export const GOOGLE_TRANSLATE_URL =
  "https://translate.google.com/#view=home&op=translate&sl=auto&tl=en&text=";
export const GOOGLE_MAPS_URL = "https://www.google.com/maps/";
export const GOOGLE_AUTH_LIB_URL = "https://apis.google.com/js/platform.js";

export const DATE_FORMAT = "ddd, Do MMM, YYYY";
export const DATETIME_FORMAT = "ddd, Do MMM YYYY - h:mma";
export const SHORT_DATE_FORMAT = "Do MMM, YYYY";

export const BOOKING_STATUS = {
  ACCEPT: "accept",
  ACCEPTED: "accepted",
  ACTIVE: "active",
  AT_CHECKPOINT: "at_checkpoint",
  AWAITING_PAYMENT: "awaiting_payment",
  CANCELLED: "canceled", // Not a typo, Airbnb uses canceled with single "l" in threads
  CANCELLED_BY_ADMIN: "cancelled_by_admin",
  CANCELLED_BY_GUEST: "cancelled_by_guest",
  CANCELLED_BY_HOST: "cancelled_by_host",
  DECLINED: "declined",
  DENIED: "denied",
  INQUIRY: "inquiry",
  NOT_POSSIBLE: "not_possible",
  PENDING: "pending",
  PREAPPROVED: "preapproved",
  SPECIAL_OFFER: "special_offer",
  TIMEDOUT: "timeout"
};

export const BOOKING_STATUS_MESSAGES = {
  [BOOKING_STATUS.ACTIVE]: {
    title: "Active!",
    message: "Booking enquiry active."
  },
  [BOOKING_STATUS.INQUIRY]: {
    title: "Inquiry!",
    message: "Booking enquiry active."
  },
  [BOOKING_STATUS.PENDING]: {
    title: "Pending!",
    message: "Booking enquiry active."
  },
  [BOOKING_STATUS.AT_CHECKPOINT]: {
    title: "Pending!",
    message: "Booking enquiry active."
  },
  [BOOKING_STATUS.DENIED]: {
    title: "Denied!",
    message: "Request declined by the host."
  },
  [BOOKING_STATUS.DECLINED]: {
    title: "Declined!",
    message: "Request declined by the host."
  },
  [BOOKING_STATUS.CANCELLED]: {
    title: "Cancelled!",
    message: "Request cancelled by host or guest."
  },
  [BOOKING_STATUS.CANCELLED_BY_ADMIN]: {
    title: "Cancelled!",
    message: "Request cancelled by Airbnb."
  },
  [BOOKING_STATUS.CANCELLED_BY_GUEST]: {
    title: "Cancelled!",
    message: "Request cancelled by the guest."
  },
  [BOOKING_STATUS.CANCELLED_BY_HOST]: {
    title: "Cancelled!",
    message: "Request cancelled by the host/Houst."
  },
  [BOOKING_STATUS.AWAITING_PAYMENT]: {
    title: "Awaiting Payment!",
    message: "Guest payment didn't go through."
  },
  [BOOKING_STATUS.NOT_POSSIBLE]: {
    title: "Not Possible!",
    message: "Selected dates are no longer available."
  },
  [BOOKING_STATUS.ACCEPT]: {
    title: "Accepted!",
    message: "Request accepted by the host or with Instant Book."
  },
  [BOOKING_STATUS.ACCEPTED]: {
    title: "Accepted!",
    message: "Request accepted by the host or with Instant Book."
  },
  [BOOKING_STATUS.PREAPPROVED]: {
    title: "Pre-Approved!",
    message: "Request pre-approved and waiting for the guest to confirm."
  },
  [BOOKING_STATUS.SPECIAL_OFFER]: {
    title: "Special Offer!",
    message: "Special offer sent and waiting for the guest to confirm."
  },
  [BOOKING_STATUS.TIMEDOUT]: {
    title: "Expired!",
    message: "Host or guest didn't respond within 24 hours."
  }
};

export const LANGUAGE_ENGLISH = "en";
export const LANGUAGE_FRENCH = "fr";
export const SUPPORTED_LANGUAGES = [LANGUAGE_ENGLISH, LANGUAGE_FRENCH];
export const MESSAGE_GREETINGS = {
  en: { salutation: "Hi", signOff: " Support Team" },
  fr: { salutation: "Bonjour", signOff: "L’équipe d’assistance de " }
};

export const GOOGLE_DOMAINS = ["airsorted.uk", "airsorted.com", "houst.com"];

export const CANCELLATION_REASONS_DESCRIPTIONS = {
  DECLINE_REASON_HOST_DOUBLE: "Problem with selected dates",
  DECLINE_REASON_HOST_CHANGE: "Reservation change declined or not possible",
  DECLINE_REASON_HOST_BAD_REVIEWS_SPARSE_PROFILE:
    "The guest has negative reviews",
  DECLINE_REASON_COVID19_HOST:
    "The host needs to cancel for reasons related to the Covid-19 pandemic.",
  DECLINE_REASON_HOST_UNAUTHORIZED_PARTY: "Party in a property",
  DECLINE_REASON_HOST_BEHAVIOR: "Guest behavior",
  DECLINE_REASON_HOST_BAD_FIT:
    "The guest broke a house rule or listing does not fit their needs.",
  DECLINE_REASON_HOST_ASKED: "Guest asked host to cancel",
  DECLINE_REASON_HOST_GUEST_MUTUAL_CANCELLATION:
    "The host wants to initiate a mutual cancellation.",
  DECLINE_REASON_HOST_OTHER: "Reason other than what was listed above."
};

export const CANCELLATION_SUBREASONS_DESCRIPTIONS = {
  DECLINE_REASON_HOST_EMERGENCY: "The host has an extenuating circumstance.",
  DECLINE_REASON_HOST_HOST_UNAVAILABLE:
    "The host cannot host on these dates anymore.",
  DECLINE_REASON_HOST_DOUBLE_BOOKED:
    "Another guest is already staying there on these dates.",
  DECLINE_REASON_HOST_RESERVATION_LENGTH:
    "The host wants a shorter or longer reservation.",
  DECLINE_REASON_HOST_DIFFERENT_PRICE: "The host wants a different price.",
  DECLINE_REASON_HOST_CHANGE_RESERVATION:
    "The host wants to change the reservation.",
  DECLINE_REASON_HOST_PARTY_REVIEWS:
    "Reviews indicating that guest have thrown a party in the past.",
  DECLINE_REASON_HOST_PARTY_INDICATION:
    "The guest indicated that they are going to throw a party.",
  DECLINE_REASON_HOST_PARTY_UNRESPONSIVE:
    "The guest has not answered my questions about their trip.",
  DECLINE_REASON_HOST_PARTY_AD: "The guest has advertised a party or event.",
  DECLINE_REASON_HOST_PARTY_OTHER:
    "I have other concerns about this guest throwing a party.",
  DECLINE_REASON_HOST_BEHAVIOR_REVIEWS:
    "Reviews indicating guest have broken house rules in the past.",
  DECLINE_REASON_HOST_BEHAVIOR_INDICATION:
    "The guest indicated that they are going to break my house rules.",
  DECLINE_REASON_HOST_BEHAVIOR_UNRESPONSIVE:
    "The guest has not answered my questions about their trip.",
  DECLINE_REASON_HOST_BEHAVIOR_OTHER:
    "I have other concerns about this guest's behavior.",
  DECLINE_REASON_HOST_GUEST_PROFILE:
    "The guest has bad reviews or not enough profile information.",
  DECLINE_REASON_HOST_NOT_FIT:
    "The guest broke a house rule or listing does not fit their needs.",
  DECLINE_REASON_HOST_OTHER_CONCERN: "I have other concerns about this guest.",
  DECLINE_REASON_HOST_GUEST_WRONG_BOOKING:
    "The booking information is wrong, and they want to rebook.",
  DECLINE_REASON_HOST_GUEST_PERSONAL_REASON:
    "Itinerary changes due to the guest's personal reasons.",
  DECLINE_REASON_HOST_GUEST_EXTENUATING_REASON:
    "Special circumstances (disasters, diseases, etc.)",
  DECLINE_REASON_HOST_GUEST_OTHER_REASON:
    "The guest asked the host to cancel due to other reasons."
};

// Short term policies:
const CANCELLATION_POLICY_FLEXIBLE = "flexible";
const CANCELLATION_POLICY_MODERATE = "moderate";
const CANCELLATION_POLICY_BETTER_STRICT_WITH_GRACE_PERIOD =
  "better_strict_with_grace_period";
const CANCELLATION_POLICY_STRICT_14_WITH_GRACE_PERIOD =
  "strict_14_with_grace_period";
const CANCELLATION_POLICY_SUPER_STRICT_30 = "super_strict_30";
const CANCELLATION_POLICY_SUPER_STRICT_60 = "super_strict_60";
// Long term policies:
const CANCELLATION_POLICY_FIRM = "firm";
const CANCELLATION_POLICY_STRICT = "strict";
const CANCELLATION_POLICY_LONG_TERM = "long_term";

export const CANCELLATION_POLICIES = {
  [CANCELLATION_POLICY_FLEXIBLE]:
    "Full refund 1 day prior to arrival. If canceled after a trip starts, refund of accommodation fee for nights not used 24 hours after the cancelation.",
  [CANCELLATION_POLICY_MODERATE]:
    "Full refund up until 5 days prior to arrival. If canceled after a trip starts, 50% refund of accommodation fee on nights not used 24 hours after the cancelation.",
  [CANCELLATION_POLICY_BETTER_STRICT_WITH_GRACE_PERIOD]:
    "Full refund up until 30 days prior to arrival. 50% refund of accommodation fee up to 1 week prior to arrival, then non-refundable.",
  [CANCELLATION_POLICY_STRICT_14_WITH_GRACE_PERIOD]:
    "Full refund within 48 hours of booking and at least 14 days of checkin. After, 50% refund up until 1 week prior to arrival",
  [CANCELLATION_POLICY_SUPER_STRICT_30]:
    "50% refund of accommodation fee up until 30 days prior to arrival, then non-refundable.",
  [CANCELLATION_POLICY_SUPER_STRICT_60]:
    "50% refund of accommodation fee up until 60 days prior to arrival, then non-refundable.",
  [CANCELLATION_POLICY_FIRM]:
    "To receive a full refund, guests must cancel at least 30 days before check-in. If a guest cancels after that, you’ll be paid 100% for all nights spent, plus 30 additional nights. If fewer than 30 days remain on the reservation when the guest cancels, you’ll be paid 100% for all of those remaining nights",
  [CANCELLATION_POLICY_LONG_TERM]:
    "To receive a full refund, guests must cancel at least 30 days before check-in. If a guest cancels after that, you’ll be paid 100% for all nights spent, plus 30 additional nights. If fewer than 30 days remain on the reservation when the guest cancels, you’ll be paid 100% for all of those remaining nights",
  [CANCELLATION_POLICY_STRICT]:
    "To receive a full refund, guests must cancel within 48 hours of booking, and the cancellation must occur at least 28 days before check-in. If a guest cancels after that, you’ll be paid 100% for all nights spent, plus 30 additional nights. If fewer than 30 days remain on the reservation when the guest cancels, you’ll be paid 100% for all of those remaining nights"
};

export const CANCELLATION_POLICIES_FRIENDLY_NAME = {
  [CANCELLATION_POLICY_FLEXIBLE]: "Flexible",
  [CANCELLATION_POLICY_MODERATE]: "Moderate",
  [CANCELLATION_POLICY_BETTER_STRICT_WITH_GRACE_PERIOD]: "Firm / Semi moderate",
  [CANCELLATION_POLICY_STRICT_14_WITH_GRACE_PERIOD]: "Semi strict",
  [CANCELLATION_POLICY_SUPER_STRICT_30]: "Strict",
  [CANCELLATION_POLICY_SUPER_STRICT_60]: "Super strict",
  [CANCELLATION_POLICY_FIRM]: "Firm long-term",
  [CANCELLATION_POLICY_LONG_TERM]: "Firm long-term",
  [CANCELLATION_POLICY_STRICT]: "Strict long-term"
};
