import "./UnhappyGuestForm.scss";

import get from "lodash/get";
import React, { Component } from "react";

import Button from "../../../components/Button";
import { AppContext } from "../../../data/AppContextContainer";
import { updateReservation } from "../../../services/api";

export default class UnhappyGuestForm extends Component {
  static contextType = AppContext;

  handleMarkGuest = e => {
    const { closeModal, booking, refreshThread } = this.props;

    e.preventDefault();

    updateReservation(booking.id, {
      unhappy_guest: true
    })
      .then(() => {
        closeModal();
        refreshThread();
      })
      .catch(error => {
        closeModal();
        this.context.showNotification({
          title: "Marking guest failed.",
          message: get(error, "response.data.error", "Please try again."),
          type: "negative"
        });
      });
  };

  render() {
    const { closeModal } = this.props;

    return (
      <form className="UnhappyGuestForm">
        <div className="UnhappyGuestForm__info">
          Marking the guest as unhappy will prevent us from writing a good{" "}
          review to the guest and possibly stop some other actions. Do you want{" "}
          to proceed?
        </div>
        <div className="UnhappyGuestForm__actions">
          <Button
            handleOnClick={closeModal}
            buttonType="negative"
            message="Cancel"
          />

          <Button
            handleOnClick={this.handleMarkGuest}
            type="submit"
            buttonType="positive"
            message="Mark guest"
          />
        </div>
      </form>
    );
  }
}
