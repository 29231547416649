import classNames from "classnames";

import { ReactComponent as ChevronIcon } from "../../assets/icons/chevron.svg";
import Button from "../Button";
import LocalTime from "../LocalTime";
import CannedResponses from "./CannedResponses";
import { MessengerTextArea } from "./MessengerTextArea";
import SuggestedResponse from "./SuggestedResponse";

const MessengerUI = ({
  booking,
  cannedMessageValue,
  handleCannedMessageSelection,
  handleReset,
  handleSendMessage,
  handleTextareaFocus,
  isExpanded,
  setIsExpanded,
  isModified,
  property,
  sending,
  setSuggestedValue,
  setValue,
  thread,
  value
}) => (
  <div className={"Messenger"}>
    <div className="Messenger__container">
      <button
        className={classNames("Messenger__close-button", {
          "Messenger__close-button--shrink": isExpanded,
          "Messenger__close-button--expand": !isExpanded
        })}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <ChevronIcon />
      </button>

      <MessengerTextArea
        isModified={isModified}
        value={value}
        isExpanded={isExpanded}
        onChange={value => setValue(value)}
        onClear={handleReset}
        onFocus={handleTextareaFocus}
      />

      <div
        className={classNames("Messenger__actions", {
          "Messenger__actions--active": isExpanded
        })}
      >
        <CannedResponses
          booking={booking}
          cannedMessageValue={cannedMessageValue}
          handleCannedMessageSelection={handleCannedMessageSelection}
          property={property}
        />

        <div className="Messenger__inner">
          <SuggestedResponse
            booking={booking}
            property={property}
            thread={thread}
            setValue={setSuggestedValue}
          />
          {property?.locality && (
            <p className="Messenger__time">
              <small>
                <LocalTime locality={property.locality} />
              </small>
            </p>
          )}
          <Button
            handleOnClick={handleSendMessage}
            buttonType="positive"
            disabled={!isModified || sending}
            message={sending ? "Sending..." : "Send message"}
          />
        </div>
      </div>
    </div>
  </div>
);

export default MessengerUI;
