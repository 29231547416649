import "./UnhappyGuestForm.scss";

import get from "lodash/get";
import React, { Component } from "react";

import Button from "../../../components/Button";
import { AppContext } from "../../../data/AppContextContainer";
import { updateReservation } from "../../../services/api";

export default class UnhappyGuestRemove extends Component {
  static contextType = AppContext;

  markHappy = () => {
    const { closeModal, booking, refreshThread } = this.props;

    updateReservation(booking.id, {
      unhappy_guest: false
    })
      .then(() => {
        closeModal();
        refreshThread();
      })
      .catch(error => {
        closeModal();
        this.context.showNotification({
          title: "Marking guest failed.",
          message: get(error, "response.data.error", "Please try again."),
          type: "negative"
        });
      });
  };

  render() {
    const { closeModal } = this.props;

    return (
      <React.Fragment>
        <p>Are you sure you want to remove the Unhappy flag from this guest?</p>

        <div className="UnhappyGuestForm__actions">
          <Button
            handleOnClick={closeModal}
            buttonType="negative"
            message="Cancel"
          />

          <Button
            handleOnClick={this.markHappy}
            type="submit"
            buttonType="positive"
            message="Mark happy!"
          />
        </div>
      </React.Fragment>
    );
  }
}
