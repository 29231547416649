import React from "react";

const DropDown = ({ options, handleSelection, defaultOption, label }) => {
  return (
    <React.Fragment>
      <strong>{label}</strong>
      <select onChange={handleSelection} value={defaultOption}>
        {options.map(option => (
          <option key={option.key} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </React.Fragment>
  );
};

export default DropDown;
