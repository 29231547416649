import "./CancellationPenalties.scss";

import React, { useEffect, useState } from "react";

import Alert from "../../../components/Alert";
import Button from "../../../components/Button";
import Loading from "../../../components/Loading";
import { getReservation } from "../../../services/api";

const CancellationPenalties = ({
  mailBoxName,
  reservation,
  setDetails,
  onCancel,
  onContinue
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [penaltyAmount, setPenaltyAmount] = useState(null);

  useEffect(() => {
    getReservation(mailBoxName, reservation.confirmation_code)
      .then(({ data }) => {
        setDetails(data.reservation?.host_cancellation?.applicable_reasons);
        setPenaltyAmount(data.reservation?.host_cancellation?.penalty_amount);
      })
      .catch(error => {
        setError(`Something went wrong fetching reservation - ${error}`);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loading message={"Checking for cancellation details..."} />;
  }

  if (error) {
    return (
      <Alert title="Something went wrong..." message={error} status="fail" />
    );
  }

  if (!penaltyAmount) {
    return (
      <div className="CancellationDetails" data-testid="details-container">
        <div className="CancellationDetails__main">
          Cancellation fee could not be determined. This could mean that a
          cancellation is free of charge, but can also mean a bug. Please log in
          to Airbnb account to proceed with cancellation.
        </div>
        <div className="CancellationDetails__footer">
          <Button buttonType="negative" handleOnClick={onCancel}>
            Back
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="CancellationDetails" data-testid="details-container">
      <div className="CancellationDetails__header">
        <strong>
          Please review cancellation penalties before moving forward:
        </strong>
      </div>
      <div className="CancellationDetails__main">
        <div className="CancellationDetails__main__item">
          &#8226; A cancellation fee of <strong>${penaltyAmount}</strong>. It
          will be withheld from the next payout.
        </div>
        <div className="CancellationDetails__main__item">
          &#8226; An automatic cancellation review will be posted on your
          listing.
        </div>
        <div className="CancellationDetails__main__item">
          &#8226; Your calendar will stay blocked for the dates of the canceled
          reservation.
        </div>
        <div className="CancellationDetails__main__item">
          &#8226; Your cancellation rate must be 1% or less for the past year to
          be eligible for Superhost status.
        </div>
      </div>
      <div className="CancellationDetails__footer">
        <Button
          data-testid="confirm-penalties"
          buttonType="positive"
          handleOnClick={onContinue}
        >
          Continue
        </Button>
        <Button buttonType="negative" handleOnClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default CancellationPenalties;
