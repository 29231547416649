import "./ThreadPreview.scss";

import { distanceInWordsStrict } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";

import GuestImage from "../../../components/GuestImage";
import {
  AIRBNB_LOGIN_URL,
  AIRBNB_SUPPORT_THREAD_TYPE
} from "../../../utils/constants";
import { SHORT_DATE_FORMAT } from "../../../utils/constants";
import { formatDate } from "../../../utils/format";

const ThreadPreview = ({ thread, mailBoxName, homeCode }) => {
  const renderInquiryGuest = ({ reservation, guest }) => {
    const today = Date.now();

    return (
      <div className="ThreadPreview__details">
        <GuestImage
          url={guest.picture_url}
          status={reservation.status}
          size="small"
        />

        <div>
          <p>
            <strong>{guest.first_name}</strong>
          </p>

          <p>{distanceInWordsStrict(thread.updated_at, today)} ago</p>

          <p className="label-info">{reservation.status}</p>
        </div>
      </div>
    );
  };

  const renderInquiryListing = ({ listing, text_preview, thread_sub_type }) => {
    const homeCode = listing?.homecode;

    if (thread_sub_type === AIRBNB_SUPPORT_THREAD_TYPE) {
      return (
        <div className="ThreadPreview__airbnb">
          <p>
            {text_preview
              ? `${text_preview.substring(0, 110)}...`
              : "No summary available"}
          </p>

          <p>
            Open{" "}
            <a
              href={AIRBNB_LOGIN_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Airbnb
            </a>{" "}
            to reply to Airbnb Support.
          </p>
        </div>
      );
    }

    return (
      <div className="ThreadPreview__view">
        {homeCode && <p data-testid="home-code">{homeCode}</p>}

        <Link to={`/mailbox/${mailBoxName}/thread/${thread.id}/${homeCode}`}>
          {text_preview
            ? `${text_preview.substring(0, 110)}...`
            : "View thread"}
        </Link>
      </div>
    );
  };

  const renderInquiryDates = ({ reservation }) => {
    const checkinDate = reservation.checkin_date;
    const checkoutDate = reservation.checkout_date;

    if (checkinDate && checkoutDate) {
      return (
        <div className="ThreadPreview__dates">
          <p>{formatDate(checkinDate, SHORT_DATE_FORMAT)}</p>
          <p>{formatDate(checkoutDate, SHORT_DATE_FORMAT)}</p>
        </div>
      );
    }
  };

  return (
    <div className="ThreadPreview">
      {renderInquiryGuest(thread)}

      <div className="ThreadPreview__right">
        {renderInquiryListing(thread)}

        {renderInquiryDates(thread)}
      </div>
    </div>
  );
};

export default ThreadPreview;
