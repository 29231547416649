import "./UpdateReservationTimes.scss";

import { isAfter } from "date-fns";
import get from "lodash/get";
import React, { Component } from "react";

import Button from "../../../components/Button";
import DropDown from "../../../components/Dropdown";
import ExpandableArea from "../../../components/ExpandableArea";
import { AppContext } from "../../../data/AppContextContainer";
import { updateReservation } from "../../../services/api";
import { getLocalTime } from "../../../utils/datetime";
import { formatTime } from "../../../utils/format";

const NUMBER_OF_INTERVAL_PER_HOUR = 2;

export default class UpdateReservationTimes extends Component {
  static contextType = AppContext;

  state = {
    checkinTime: null,
    checkoutTime: null
  };

  componentDidMount() {
    const { booking } = this.props;

    if (booking) {
      this.setState({
        checkinTime: booking.checkin_time,
        checkoutTime: booking.checkout_time
      });
    }
  }

  handleApiCall = () => {
    const { booking, refreshThread } = this.props;
    const data = {
      checkin_time: this.state.checkinTime,
      checkout_time: this.state.checkoutTime
    };

    updateReservation(booking.id, data)
      .then(() => {
        this.context.showNotification({
          title: "Success",
          message: (
            <React.Fragment>
              Check-in is now {formatTime(this.state.checkinTime)} <br />
              Check-out is now {formatTime(this.state.checkoutTime)}
            </React.Fragment>
          ),
          type: "positive"
        });

        refreshThread();
      })
      .catch(error => {
        this.context.showNotification({
          title: "Update failed",
          message: get(
            error,
            "response.data.error",
            "Please refresh the app and try again."
          ),
          type: "negative"
        });
      });
  };

  dropDownUpdater = (event, reservationTimeType) => {
    this.setState({
      [reservationTimeType]: event.target.value
    });
  };

  getHour = index => {
    const hour = Math.floor(index / NUMBER_OF_INTERVAL_PER_HOUR);
    return hour < 10 ? "0" + hour : hour;
  };

  //It will increment the minutes by the intervals
  getMinute = index => {
    const interval = index % NUMBER_OF_INTERVAL_PER_HOUR;
    const minutesInHour = 60;

    if (interval === 0) {
      return "00";
    }

    return (minutesInHour / NUMBER_OF_INTERVAL_PER_HOUR) * interval;
  };

  generateOption = () => {
    const options = [];
    const hoursInDay = 24;
    for (let i = 0; i < hoursInDay * NUMBER_OF_INTERVAL_PER_HOUR; i++) {
      const hour = this.getHour(i);
      const min = this.getMinute(i);
      options.push({
        key: `${hour}:${min}:00`,
        value: `${hour}:${min}:00`,
        text: `${hour}:${min}`
      });
    }

    return options;
  };

  renderReview = (booking, locality) => {
    const reservationTimeOptions = this.generateOption();
    const ifFutureCheckIn = isAfter(
      booking.checkin_datetime,
      getLocalTime(locality, Date.now())
    );

    return (
      <div className="UpdateReservationTimes">
        <div className="UpdateReservationTimes__section">
          {ifFutureCheckIn && (
            <div className="UpdateReservationTimes__section__item">
              <DropDown
                label="Check-in time:"
                options={reservationTimeOptions}
                handleSelection={e => this.dropDownUpdater(e, "checkinTime")}
                defaultOption={this.state.checkinTime}
              />
            </div>
          )}

          <div className="UpdateReservationTimes__section__item">
            <DropDown
              label="Check-out time:"
              options={reservationTimeOptions}
              handleSelection={e => this.dropDownUpdater(e, "checkoutTime")}
              defaultOption={this.state.checkoutTime}
            />
          </div>

          <Button
            handleOnClick={this.handleApiCall}
            buttonType="positive"
            message="Save"
            className="small"
          />
        </div>
      </div>
    );
  };

  render() {
    const { booking } = this.props;

    if (!booking) {
      return null;
    }

    const locality = booking.property.locality;
    const localTimeNow = getLocalTime(locality, Date.now());
    const checkoutInFuture = isAfter(booking.checkout_datetime, localTimeNow);

    if (booking.checkin_time && booking.checkout_time && checkoutInFuture) {
      return (
        <ExpandableArea
          expandedContent={this.renderReview(booking, locality)}
          expandableTitle="Update check-in and check-out time"
        />
      );
    }

    return null;
  }
}
