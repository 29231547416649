import "./BookingComments.scss";

import React from "react";

import SentMetaData from "../../../components/SentMetaData";

const BookingComments = ({ comments }) => {
  return comments.length ? (
    <div className="BookingComments">
      <span className="BookingComments__title">Notes ({comments.length})</span>
      <div className="BookingComments__list">
        {comments.map((comment, index) => (
          <div key={index} className="BookingComments__comment">
            {comment.text}
            <SentMetaData
              datetime={comment.created_at}
              actioned_by={comment.operator.full_name}
            />
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default BookingComments;
