import "./Modal.scss";

import React, { Component } from "react";

export default class Modal extends Component {
  render() {
    const { title, children, close } = this.props;

    return (
      <React.Fragment>
        <button className="Modal-overlay" onClick={close} />

        <div className="Modal">
          <div className="Modal__title">
            <h4>{title}</h4>
          </div>

          <div className="Modal__content">{children}</div>
        </div>
      </React.Fragment>
    );
  }
}
