import "./AlterationRequest.scss";
import "react-datepicker/dist/react-datepicker.css";

import { isAfter } from "date-fns";
import get from "lodash/get";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import NumericInput from "react-numeric-input";

import Button from "../../../components/Button";
import ExpandableArea from "../../../components/ExpandableArea";
import Spinner from "../../../components/Spinner";
import { AppContext } from "../../../data/AppContextContainer";
import { createAlteration, getPriceQuote } from "../../../services/api";
import { BOOKING_STATUS } from "../../../utils/constants";
import { getDate } from "../../../utils/datetime";
import { formatPrice } from "../../../utils/format";
import { getPendingRequestStatus } from "../../../utils/statusInfo";

const { ACCEPT, ACCEPTED } = BOOKING_STATUS;
const ALTERATION_STATUS_PENDING = "PENDING";

export default class AlterationRequest extends Component {
  static contextType = AppContext;

  state = {
    startDate: null,
    endDate: null,
    numberOfAdults: null,
    numberOfChildren: null,
    numberOfInfants: null,
    quoteHostPayout: null,
    quoteGuestPrice: null,
    priceOverride: null,
    inputChanged: false,
    loading: false
  };

  componentDidMount() {
    const { reservation } = this.props;

    if (reservation) {
      this.setState({
        startDate: new Date(reservation.checkin_date),
        endDate: new Date(reservation.checkout_date),
        numberOfAdults: reservation.number_of_adults,
        numberOfChildren: reservation.number_of_children,
        numberOfInfants: reservation.number_of_infants
      });
    }
  }

  changeInput = (input, value) => {
    this.setState({ [input]: value });
    this.setState({ inputChanged: true });
  };

  formatParams = () => {
    const {
      startDate,
      endDate,
      numberOfAdults,
      numberOfChildren,
      numberOfInfants
    } = this.state;

    return {
      start_date: startDate,
      end_date: endDate,
      number_of_adults: numberOfAdults,
      number_of_children: numberOfChildren,
      number_of_infants: numberOfInfants
    };
  };

  getNewPriceQuote = () => {
    const { reservation, mailBoxName } = this.props;
    const params = this.formatParams();

    this.setState({ loading: true });

    getPriceQuote(mailBoxName, reservation.confirmation_code, params)
      .then(({ data }) => {
        this.setState({
          quoteHostPayout: data.price_quote.expected_payout_amount_accurate,
          quoteGuestPrice: data.price_quote.listing_base_price_accurate
        });
      })
      .catch(error => {
        this.setState({ newPrice: null });
        this.context.showNotification({
          title: "Failed to fetch price quote.",
          message: get(
            error,
            "response.data.error",
            "Please refresh the app and try again."
          ),
          type: "negative"
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
          inputChanged: false
        });
      });
  };

  handleSubmit = () => {
    const { mailBoxName, thread, reservation, refreshThread } = this.props;
    const { priceOverride } = this.state;
    const params = this.formatParams();

    if (!!priceOverride) {
      params["total_price"] = priceOverride;
    }

    this.setState({ loading: true });

    createAlteration(
      mailBoxName,
      reservation.confirmation_code,
      thread.id,
      params
    )
      .then(() => {
        refreshThread();
        this.context.showNotification({
          title: "Alteration created successfully."
        });
      })
      .catch(error => {
        this.context.showNotification({
          title: "Failed to create alteration.",
          message: get(
            error,
            "response.data.error",
            "Please refresh the app and try again."
          ),
          type: "negative"
        });
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  };

  disableSubmitButton = () => {
    const { inputChanged } = this.state;

    if (!this.newPrice()) {
      return true;
    }

    return inputChanged;
  };

  newPrice = () => {
    const { quoteGuestPrice, priceOverride } = this.state;

    return !!priceOverride ? priceOverride : quoteGuestPrice;
  };

  newPayout = () => {
    const { quoteHostPayout, priceOverride } = this.state;
    const { reservation } = this.props;

    return !!priceOverride
      ? "N/A"
      : formatPrice(quoteHostPayout, reservation.currency);
  };

  alterationRequest = () => {
    const { loading, inputChanged } = this.state;
    const { reservation } = this.props;
    const currency = reservation.currency;

    return (
      <div className="AlterationRequest" data-testid="alteration">
        <div className="AlterationRequest__section">
          <div className="AlterationRequest__section__date">
            <label htmlFor="input-checkin">New checkin date:</label>
            {/* TODO: Change to european locale after upgrading date-fns */}
            <DatePicker
              id="input-checkin"
              className="AlterationRequest__section__datepicker"
              selected={this.state.startDate}
              onChange={date => this.changeInput("startDate", date)}
            />
          </div>
          <div className="AlterationRequest__section__date">
            <label htmlFor="input-checkout">New checkout date:</label>
            <DatePicker
              id="input-checkout"
              className="AlterationRequest__section__datepicker"
              selected={this.state.endDate}
              onChange={date => this.changeInput("endDate", date)}
            />
          </div>
          <div className="AlterationRequest__section__date">
            <label htmlFor="input-price">Override price:</label>
            <NumericInput
              id="input-price"
              className="AlterationRequest__section__price"
              min={0}
              value={this.state.priceOverride}
              onChange={n => this.setState({ priceOverride: n })}
              strict
            />
          </div>
        </div>
        <div className="AlterationRequest__section">
          <div className="AlterationRequest__section__guests">
            <label htmlFor="input-adults">Number of adults:</label>
            <NumericInput
              id="input-adults"
              className="AlterationRequest__section__guest_number"
              min={0}
              value={this.state.numberOfAdults}
              onChange={n => this.changeInput("numberOfAdults", n)}
              strict
            />
          </div>
          <div className="AlterationRequest__section__guests">
            <label htmlFor="input-children">Number of children:</label>
            <NumericInput
              id="input-children"
              className="AlterationRequest__section__guest_number"
              min={0}
              value={this.state.numberOfChildren}
              onChange={n => this.changeInput("numberOfChildren", n)}
              strict
            />
          </div>
          <div className="AlterationRequest__section__guests">
            <label htmlFor="input-infants">Number of infants:</label>
            <NumericInput
              id="input-infants"
              className="AlterationRequest__section__guest_number"
              min={0}
              value={this.state.numberOfInfants}
              onChange={n => this.changeInput("numberOfInfants", n)}
              strict
            />
          </div>
        </div>
        <div className="AlterationRequest__section__buttons">
          <div className="AlterationRequest__section__button">
            <Button
              handleOnClick={this.getNewPriceQuote}
              buttonType="positive"
              message="Check new expected price"
              disabled={!inputChanged}
              className="small"
            />
          </div>
          {loading ? (
            <Spinner />
          ) : (
            this.newPrice() && (
              <div className="AlterationRequest__section__buttons">
                <div className="AlterationRequest__section__prices">
                  <div>
                    Guest price:{" "}
                    <strong>{formatPrice(this.newPrice(), currency)}</strong>
                  </div>
                  <div>
                    Host payout: <strong>{this.newPayout()}</strong>
                  </div>
                </div>
                <div className="AlterationRequest__section__button">
                  <Button
                    id="submit-alteration"
                    handleOnClick={this.handleSubmit}
                    buttonType="positive"
                    message="Send request"
                    disabled={this.disableSubmitButton()}
                    className="small"
                  />
                </div>
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  shouldDisplayAlterationRequest = () => {
    const { reservation, thread } = this.props;

    if (!reservation || ![ACCEPTED, ACCEPT].includes(reservation.status)) {
      return null;
    }

    if (reservation.alteration?.status === ALTERATION_STATUS_PENDING) {
      return false;
    }

    return !isAfter(getDate(Date.now()), getDate(thread?.checkout_date));
  };

  render() {
    const { thread } = this.props;

    if (!this.shouldDisplayAlterationRequest()) {
      return null;
    }

    const pendingRequestStatus = getPendingRequestStatus(thread);

    if (pendingRequestStatus === null) {
      return (
        <ExpandableArea
          expandedContent={this.alterationRequest()}
          expandableTitle="Request alteration"
        />
      );
    }

    return (
      <div className="AlterationRequest">
        {pendingRequestStatus} request sent to Airbnb
      </div>
    );
  }
}
