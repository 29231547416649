import "./SuggestedResponse.scss";

import React from "react";

import { ReactComponent as AIIcon } from "../../assets/icons/openai-icon.svg";
import Button from "../Button";
import { ErrorMessageContainer } from "../ErrorMessageContainer";
import Loading from "../Loading";
import { useLocalStorageSuggestedResponse } from "./useLocalStorageSuggestedResponse";

const SuggestedResponse = ({ thread, booking, property, setValue }) => {
  const {
    loading,
    error,
    getSuggestedResponse
  } = useLocalStorageSuggestedResponse(thread, booking, property);

  const onClick = () => {
    setValue(getSuggestedResponse());
  };

  return (
    <div className="SuggestedResponse">
      <Button
        type="button"
        onClick={onClick}
        title="Suggested response"
        className="SuggestedResponse__button"
        disabled={loading}
      >
        <AIIcon className="SuggestedResponse__icon" />
        {loading && <Loading className="SuggestedResponse__loader" />}
      </Button>

      {error && (
        <div className="SuggestedResponse__error">
          <ErrorMessageContainer>
            An error occurred, please try again later
          </ErrorMessageContainer>
        </div>
      )}
    </div>
  );
};

export default SuggestedResponse;
