import "./Loading.scss";

import React from "react";

import Spinner from "./Spinner";

const Loading = ({ className, message }) => {
  return (
    <div className={`Loading ${className}`}>
      <Spinner />

      <p className="Loading__message" data-testid="loading-text">
        {message}
      </p>
    </div>
  );
};

export default Loading;
